.header {
    height: 80px;
    display: flex;
    justify-content: space-between;
    padding: 15px 50px;
    align-items: center;
}

.header img {
    height: 50px;    
}

.header ul {
    display: flex;
    list-style-type: none;
    align-items: center;
}

.header ul li {
    padding: 10px;
}

.header_menu {
    display: flex;
    justify-content: center;
    align-items: center;
}

.header_menu img {
    height: 50px;
    margin-right: 10px;
}

.header_profile {
    line-height: 0%;    
}

.header_profile h3{
    display: flex;
    align-items: center;
}

.header_profile h3 img{
    height: 10px;
    margin-left: 5px;
}
@media screen and (max-width: 500px) {

    .header {
        padding: 15px 0px;
    }
    .header img{
        padding: 0px 5px;
         margin-left: 10px;
    }
  }
  