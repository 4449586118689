.topic_page {
  /* height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center; */
  padding: 0px 150px;
}

.note_page {
  width: 100%;
  background-color: #d0c8ff;
  border-radius: 15px;
  margin: 30px 0px;
  padding: 20px;
}
.note_sub_page {
  box-shadow: 0px 0px 11px rgb(0 0 0 / 7%);
  background-color: white;
  padding: 15px;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.topic_page_body {
  /* padding: 10px 50px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
@media  (min-width: 601px) and (max-width: 1100px) {
  .topic_page {
    padding: 0px 50px;
  }
}
@media screen and (max-width: 600px) {
  .topic_page {
    padding: 0px ;}
/* @media screen and (max-width: 500px) {
  .note_page {
   padding: 5px;
   
  }
  .topic_page_body {
    margin-top: -40%;
  } */
}

