.main_title h3 {
  font-size: 30px;
  margin: 5px 0px;
}
@media screen and (max-width: 500px) {
  .main_title h3 {
    font-size: 15px;
    margin: 5px 6px;
  }
}
