.main_bg {
    background-image: url("../assets/images/Green.png");
    background-repeat: no-repeat;
}

.button {
    border-radius: 5px;
    background-color: #0090d0;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    font-size: 20px;
    margin-top: 20px;
    padding: 15px;
    
  }

.text_field {
    margin-top: 20px;
}

.card_button {
    border-radius: 5px;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    font-size: 15px;
    font-weight: 600;
    margin: 20px;
    padding: 15px;
}
@media screen and (max-width: 500px) {
    .button {
        border-radius: 5px;
        background-color: #0090d0;
        border: none;
        color: white;
        text-align: center;
        text-decoration: none;
        font-size: 20px;
        margin-top: 20px;
        padding: 15px;
      }
    
  }
  