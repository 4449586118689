.auth_page {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth_box {
  box-shadow: 0px 0px 11px rgb(0 0 0 / 7%);
  background-color: white;
  max-width: 400px;
  padding: 15px;
  margin: 10px;
  border-radius: 2%;
  display: flex;
  flex-direction: column;
}

.auth_title {
  color: #001b27;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 25px;
}

.auth_text {
  padding-top: 6px;
  padding-right: 8%;
  color: gray;
  margin-left: 47px;
}

.verify_button {
  text-align: center;
  color: white;
  margin-top: 25px;
  width: 52vh;
}

.auth_subtitle {
  margin: 20px 0 10px;
  color: gray;
}

.error_message {
  color: red;
  text-align: left;
  padding-top: 5px;
}
