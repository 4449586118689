@import "./styles/main.css";
@import "./styles/card.css";
@import "./styles/auth.css";
@import "./styles/header.css";
@import "./styles/footer.css";
@import "./styles/landingPage.css";
@import "./styles/mainTitle.css";
@import "./styles/topicPage.css";
@import "./styles/chapterPage.css";
@import "./styles/shopPage.css";

.App_body {
  text-align: center;
} 

.container-iframe {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}