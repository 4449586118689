.landing_page {
    display: flex;
    flex-wrap: wrap;
    margin: 40px;
    align-items: flex-start;
    justify-content: left;
}
.display-flex {
    display: flex;
    justify-content: center;
}
.landing_page_height {
    height: 100vh;
    align-items: center;
}

@media screen and (max-width: 500px) {
    .landing_page {
        margin: 4px;
      
    }
  }
  

