.subject_card {
  z-index: 0;
  width: 300px;
  height: 135px;
  position: relative;
  overflow: hidden;
  border: none;
  border-radius: 10px;
  padding: 16px 24px;
  margin: 10px;
  box-shadow: 0 22px 45px rgb(136 148 162 / 20%);
}

.subject_card_c1 {
  position: absolute;
  width: 257px;
  left: -20px;
  top: -9.06%;
  bottom: 21.89%;
  background: radial-gradient(
    76.17% 76.17% at 14.47% 16.81%,
    #ffffff 52.07%,
    rgba(255, 255, 255, 0) 100%
  );
  opacity: 0.3;
}

.subject_card_c2 {
  z-index: -1;
  position: absolute;
  width: 248px;
  right: -100px;
  top: 35%;
  bottom: -15.07%;
  border-radius: 46%;
  background: radial-gradient(
    76.17% 76.17% at 14.47% 16.81%,
    #ffffff 52.07%,
    rgba(255, 255, 255, 0) 100%
  );
  opacity: 0.2;
}
.subject_card h3 {
  font-size: 25px;
  line-height: 0;
}

.subject_card_body {
  display: flex;
}

.subject_card_body img {
  height: 80px;
  margin-right: 15px;
}
.subject_card_body button {
  float: right;
}
.topic_card {
  width: 475px;
  max-height: 125px;
  position: relative;
  overflow: hidden;
  border: none;
  border-radius: 10px;
  padding: 16px 24px;
  margin: 20px 20px;
  background: #ffffff;
  box-shadow: 0 22px 45px rgb(136 148 162 / 20%);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.topic_card_img {
  border-radius: 100px;
  height: 80px;
  width: 80px;
  object-fit: cover;
}
.topic_card_content {
  width: 100%;
  margin: 0px 10px;
}
.topic_card_icon {
  font-size: 30px;
}
.package_card {
  border-radius: 8px;
  background-color: #0090d0;
  border: none;
  text-decoration: none;
  padding: 15px;
  /* max-width: 600px; */
  margin: 10px 0px 15px;
}
.package_card h3,
h2 {
  line-height: 1;
  margin: 5px 0px;
}
.package_card p {
  margin: 5px;
}
.package_card_body {
  display: flex;
  justify-content: space-between;
  height: 50px;
  align-items: center;
}
.cart_card {
  /* background: #ffffff; */
  max-width: 600px;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 20px; */
}
.cart_card .head {
  font-size: 25px;
  font-weight: 500;
  margin: 5px 0;
}
.cart_card_body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px;
}
.cart_card_subject {
  display: flex;
  align-items: center;
}
.cart_card_subject p {
  margin: 0 10px;
}
.cart_card_body a {
  color: #28b0db;
  text-decoration: none;
}
.cart_card_body ul {
  list-style-type: none;
}
.cart_card_body ul li {
  text-align: center;
  line-height: 1;
  font-size: 15px;
}
.cart_card_para {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 100px;
}
/* .shop_card {
  display: flex;
  justify-content: center;
  align-items: center;
 
} */
.shop_card_body {
  text-align: center;
  padding: 25px;
  border-radius: 4px;
  box-shadow: 0 22px 45px rgb(136 148 162 / 20%);
  height: auto;
}
.shop_card_body .MuiDialogContent-root {
  padding: 25px;
}

@media screen and (max-width: 500px) {
  .shop_card_body .MuiDialogContent-root {
    padding: 0px;
  }
  .package_card {
    padding: 15px;
    margin: 10px 0px 15px;
  }
  .package_card h3,
  h2 {
    margin: 5px 0px;
  }
  .package_card p {
    margin: 5px;
  }
  .package_card_body {
    font-size: 10px;
  }

  .MuiOutlinedInput-input {
    padding: 18.5px 0px;
  }
  .topic_card {
    font-size: 10px;
  }
  .topic_card_img {
    height: 50px;
    width: 50px;
  }
  .topic_card_icon {
    font-size: 20px;
  }
}
