.chapter_page_bg {
  background: linear-gradient(136.2deg, #99e0ff 0%, #caefff 99.18%);
  padding: 10px;
  margin: 10px 40px 40px;
  border-radius: 10px;
}
.chapter_page_lesson {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media screen and (max-width: 500px) {
  .chapter_page_bg {
    margin: 10px 0px 40px;
  }
 
}
